import { Component } from 'react'
import Form from "react-bootstrap/Form"
import { TbFilterSearch } from 'react-icons/tb'
import { FaRegBell, FaUserCircle, FaLocationArrow, FaArrowLeft, FaSearchLocation } from 'react-icons/fa'
import { PiUserSwitchBold } from 'react-icons/pi'
import { BiReset } from 'react-icons/bi'
import Checkmark from '../images/checkmark.svg'
import NodeTypes from './nodeTypes'
import './topMenu.css'

class TopMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            foul: false,
            showFoul: false
        }

        this.handleMonthChanged = this.handleMonthChanged.bind(this)
        this.handleDaySelected = this.handleDaySelected.bind(this)
        this.handleMonthSelected = this.handleMonthSelected.bind(this)
        this.handleOptionsSelected = this.handleOptionsSelected.bind(this)
        this.handleResetOptions = this.handleResetOptions.bind(this)
    }

    handleSettings() {
        this.props.onSettings()
    }

    handleModeSelect(mode) {
        if (this.props.user || mode === 'fan') {
            this.props.onMode(mode)
        } else {
            this.setState({ foul: true })
            setTimeout(() => this.setState({ showFoul: true }), 250)
        }
    }

    handleLocationCheck() {
        this.props.onLocCheck()
    }

    handleSearch() {
        this.props.onSearch()
    }

    handleNotificationsSelect() {
        this.props.onNotifications()
    }

    handlePrefSelect() {
        this.props.onPref()
    }

    handleMonthChanged(dir) {
        this.props.onMonthChanged(dir)
    }

    handleDaySelected(day) {
        this.props.onDaySelected(day)
    }

    handleMonthSelected() {
        this.props.onMonthSelected()
    }

    handleOptionsSelected(node, cat) {
        this.props.onOptionsSelected(node, cat)
    }

    handleGetMyLoc() {
        this.props.onGetMyLoc()
    }

    handleCategoriesChanged(c) {
        this.props.onCategoryChanged(c)
    }

    handleResetOptions() {
        this.props.onResetOptions()
    }

    handleNameChanged = event => {
        this.props.onNameChange(event.target.value.replace(/[<>]/g, ''))
    }

    handleInactive() {
        this.props.onInactive()
    }

    getValid() {
        return this.props.name.length > 3
    }

    getSearch() {
        return <div className='Search-Container'>
            {this.props.onSearch && <div className='Settings-Row'>
                <Form.Group controlId="loc" className="form-group-lg">
                    <div className='Search-Spread'>
                        <div style={{ fontSize: '14pt', color: '#fff', cursor: 'pointer' }} onClick={() => this.handleSearch()}><FaArrowLeft /></div>
                        <div style={{ fontSize: '14pt', color: '#fff', cursor: 'pointer' }} onClick={() => this.handleSearch()}><FaSearchLocation /></div>
                    </div>
                    <Form.Group className="form-group form-group-lg">
                        <Form.Label className='Search-Label form-label'>Status</Form.Label>
                        <Form.Check style={{ fontSize: '12pt', margin: '5px', color: '#fff' }} type='checkbox' id='inactive' label={this.props.inactive ? 'Inactive' : 'Active'} checked={this.props.inactive} onChange={() => this.handleInactive()} />
                    </Form.Group>
                    <Form.Label className='Search-Label'>Location<span style={{ marginLeft: '8px' }} onClick={() => this.handleGetMyLoc()}><FaLocationArrow /></span></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Search Location"
                        value={this.props.loc}
                        onChange={this.props.onLocChanged}
                        isValid={this.props.locIsGood}
                        isInvalid={!this.props.locIsGood}
                    />
                </Form.Group>
                <div className='Search-Row'>
                    <div className='Search-Label Search-Link' onClick={() => this.handleLocationCheck(this.state.loc)}>Look Up{this.props.locIsGood && <span style={{ marginLeft: '5px' }}><img src={Checkmark} alt="Verified" className='Search-Location-Checkmark' /></span>}</div>
                    <div style={{ width: '50%' }}>
                        <Form.Label style={{ color: '#fff', width: '100%' }}>
                            <div className='Search-Spread'>
                                <div>Distance {this.props.dist} mi</div>
                                <div onClick={this.props.onClearDist}><BiReset /><span>Clear</span></div>
                            </div>
                        </Form.Label>
                        <Form.Range value={this.props.dist} onChange={this.props.onDistChange} />
                    </div>
                </div>
            </div>
            }
            <Form.Group controlId="fn" className="form-group-lg Signup-Group">
                <Form.Label className='Pref-Label'>Venue/Contributor Name</Form.Label>
                <Form.Control
                    autoFocus
                    placeholder="Node Name"
                    type="text"
                    value={this.props.name}
                    onChange={this.handleNameChanged}
                    isValid={this.getValid()}
                />
            </Form.Group>
            {
                this.props.options && <div><NodeTypes options={this.props.options} onOptionsSelected={this.handleOptionsSelected} pref={this.props.pref} onResetOptions={this.handleResetOptions} /></div>
            }
            {
                this.props.categories && <div className='Search-Row'>
                    {this.getCategories()}
                </div>
            }
        </div>
    }

    getCategories() {
        return this.props.categories ? <div>
            <Form.Group className="form-group form-group-lg">
                <Form.Label className='Search-Label form-label'>Categories</Form.Label>
            </Form.Group><div className='Search-Flex'>
                {
                    this.props.categories.map((c, cidx) => {
                        return <Form.Check style={{ fontSize: '12pt', margin: '5px', minWidth: '0px' }} key={`cat-${cidx}`} type='checkbox' id={c} label={c} checked={this.props.categoryIsChecked(c)} onChange={() => this.handleCategoriesChanged(c)} />
                    })
                }</div>
        </div> : null
    }

    render() {
        //console.log(this.state, this.props)
        return <div className={`TopMenu-Container TopMenu-BG-${this.props.mode}`}>
            <div className='TopMenu-Left'>
                {this.props.search && <div className='TopMenu-Item' onClick={() => this.handleSearch()}><TbFilterSearch /></div>}
            </div>
            <div className='TopMenu-Right'>
                {this.props.notifications && <div className='TopMenu-Item' onClick={() => this.handleNotificationsSelect()}>
                    <FaRegBell />
                    {
                        this.props.notifications ? <div className='TopMenu-Notifications'>{this.props.notifications}</div> : null
                    }
                </div>}
                {this.props.onSettings ? <div className='TopMenu-Item' onClick={() => this.handleSettings()}><PiUserSwitchBold /></div> : <div className='TopMenu-Item'></div>}
                {
                    this.props.user ? (this.props.user.image ? <div onClick={() => this.handlePrefSelect()}>
                        <img className='TopMenu-Img' src={`https://localtalent-upload.s3.amazonaws.com/public/${this.props.user.image}`} alt={`Welcome ${this.props.user.firstName}`} />
                    </div> :
                        <div className='TopMenu-User' onClick={() => this.handlePrefSelect()}><div style={{ fontSize: '23pt', marginTop: '-11px' }}>{this.props.user.firstName.substring(0, 1)}</div></div>) :
                        <div className='TopMenu-User' onClick={() => this.handlePrefSelect()}><FaUserCircle /></div>

                }
            </div>
            {this.props.displaySearch ? this.getSearch() : null}
        </div>
    }
}

export default TopMenu