import React, { Component } from 'react'
import Form from "react-bootstrap/Form"
import { LinkContainer } from 'react-router-bootstrap'
import LoaderButton from '../components/loaderButton'
import Card from 'react-bootstrap/Card'
import { Auth, API, Storage } from 'aws-amplify';
import { v1 as uuidv1 } from 'uuid'
import withRouter from '../components/withRouter'
import moment from 'moment'
import Checkmark from '../images/checkmark.svg'
import NodeTypes from '../components/nodeTypes'
import { FaRegWindowClose } from 'react-icons/fa'
import { MdAddAPhoto, MdChevronLeft, MdImageNotSupported } from 'react-icons/md'
import './signup.css'

class Signup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            email: '',
            pw: '',
            cpw: '',
            code: '',
            fn: '',
            ln: '',
            zip: '',
            dob: '',
            user: false,
            image: '',
            privacy: false,
            subscribe: false,
            tou: false,
            file: null,
            curr: '1',
            imgUrl: null,
            legal: false,
            page: 'privacy',
            defaultLoc: null,
            loc: '',
            lastLoc: '',
            locIsGood: false,
            codeSent: false,
            pref: null,
            options: null,
            expanded: [],
            imageSize: false,
            msg: '',
            displayMsg: false,
            showLoginLink: false,
            reset: false
        }
        this.handleOptionSelect = this.handleOptionSelect.bind(this)
    }

    componentDidMount() {
        if (this.props.isAuthenticated && this.props.user) {
            this.props.onLogout()
        }
        if (this.props.myloc) {
            API.get('lt', `geo?coord=${this.props.myloc.lat},${this.props.myloc.lng}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        let zip = resp.data.results[0].formatted_address.split(', ')
                        const loc = zip[zip.length - 2].substr(3)
                        this.setState({ loc, lastLoc: loc, defaultLoc: this.props.myloc })
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        } else {
            const gl = navigator.geolocation
            gl.getCurrentPosition(position => {
                this.props.onMyLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                API.get('lt', `geo?coord=${position.coords.latitude.toFixed(7)},${position.coords.longitude.toFixed(7)}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                    .then(resp => {
                        if (resp.data && resp.data.results[0]) {
                            let zip = resp.data.results[0].formatted_address.split(', ')
                            const loc = zip[zip.length - 2].substr(3)
                            this.setState({ loc, lastLoc: loc, defaultLoc: { lat: position.coords.latitude, lng: position.coords.longitude } })
                        }
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }, err => { console.log(err) })
        }
    }

    handleClearErr() {
        this.setState({ err: null })
    }

    handleLocationCheck() {
        if (this.state.loc && this.state.loc !== this.state.lastLoc) {
            this.setState({ locIsGood: false })
            API.get('lt', `geo?address=${this.state.loc.replace(/#/g, '%23')}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        if (resp.data.results[0].geometry && resp.data.results[0].geometry.location) this.props.onMyLoc(resp.data.results[0].geometry.location)
                        const loc = resp.data.results[0].formatted_address || ''
                        this.setState({ loc, lastLoc: loc, locIsGood: true, defaultLoc: resp.data.results[0].geometry && resp.data.results[0].geometry.location ? resp.data.results[0].geometry.location : this.state.defaultLoc })
                    }
                })
                .catch(e => {
                    this.setState({ locIsGood: false })
                    console.log(e)
                })
        }
    }

    handleChange = event => {
        switch (event.target.id) {
            case 'pw':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d\w!@#$%^&*()_+-=[\]{}|<>]/g, "").substring(0, 127) })
                break
            case 'cpw':
                this.setState({ [event.target.id]: event.target.value.replace(/[<>// ]/g, "") })
                break
            case 'email':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@.]/g, "").toLowerCase() })
                break
            case 'zip':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d]/g, "") })
                break
            case 'loc':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-z,A-Z0-9 ]/g, "") })
                break
            case 'dob':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d-/\\]/g, '') })
                break
            default:
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9- ]/g, "") })
        }
    }

    handleImageChange(e) {
        const file = e.target.files[0]
        if (file.size < 5242880) {
            if (file.type.indexOf('image/') > -1) {
                let image = uuidv1() + file.type.replace('image/', '.')
                this.setState({ file, image, imgUrl: URL.createObjectURL(file) })
            }
        } else this.setState({ imageSize: true })
        this.fileInput.value = ''
    }

    async handleUploadImage() {
        if (this.state.file && this.state.image.length > 0) {
            await Storage.put(`${this.state.image}`, this.state.file, {
                progressCallback(progress) { console.log(`Uploaded: ${progress.loaded}/${progress.total}`) }
            })
        }
    }

    handleSubmit = async event => {
        event.preventDefault()
        window.scrollTo(0, 0)
        if (this.formIsValid()) {
            this.setState({ isLoading: true })
            Auth.signUp({ username: this.state.email, password: this.state.pw })
                .then(resp => {
                    this.setState({ curr: '2' })
                })
                .catch(e => {
                    console.log(e)
                    if (e.code === 'UsernameExistsException') {
                        Auth.signIn(this.state.email, this.state.pw)
                            .then(resp => {
                                API.get('lt', 'user')
                                    .then(resp => {
                                        this.props.setUser({ ...resp.data })
                                        this.props.userHasAuthenticated(true)
                                        this.handleDone()
                                    })
                                    .catch(e => {
                                        API.post('lt', 'user', {
                                            body: {
                                                email: this.state.email.toLowerCase(),
                                                firstName: this.state.fn,
                                                lastName: this.state.ln,
                                                zip: this.state.zip,
                                                dob: moment(this.state.dob).format('YYYY-MM-DD'),
                                                tou: this.state.tou,
                                                privacy: this.state.privacy,
                                                subscribe: this.state.subscribe
                                            }
                                        })
                                            .then(resp => {
                                                console.log('here at move the user along')
                                                this.props.setUser({ ...resp.user })
                                                this.props.userHasAuthenticated(true)
                                                this.setState({ curr: '3' })
                                            })
                                            .catch(e => {
                                                this.setState({ isLoading: false, msg: e.message, displayMsg: true })
                                                console.log(e)
                                            })
                                    })
                            })
                            .catch(e => {
                                if (e.code === 'UserNotConfirmedException') {
                                    Auth.resendSignUp(this.state.email)
                                        .then(resp => {
                                            this.setState({ curr: '2' })
                                        })
                                        .catch(e => {
                                            console.log(e)
                                            this.setState({ msg: e.message, displayMsg: true })
                                        })
                                } else {
                                    this.setState({ msg: 'This account already exists. If this is your account, select Log In below. Forgot your password? Use Log In below to reset. If you were unsuccessful in completing your account registration, please select OK to reset your account and we will notify you when Sign Up is available.', displayMsg: true, showLoginLink: true, reset: true })
                                    console.log(e.message)
                                }
                            })
                    } else {
                        this.setState({ msg: e.message, displayMsg: true })
                        console.log(e.message)
                    }
                })
            this.setState({ isLoading: false })
        }
    }

    handleConfirmSubmit = async event => {
        event.preventDefault()
        if (this.codeIsValid()) {
            window.scrollTo(0, 0)
            this.setState({ isLoading: true })
            try {
                await Auth.confirmSignUp(this.state.email, this.state.code)
                await Auth.signIn(this.state.email, this.state.pw)
                //let currUser = await Auth.currentAuthenticatedUser()
                API.post('lt', 'user', {
                    body: {
                        email: this.state.email.toLowerCase(),
                        firstName: this.state.fn,
                        lastName: this.state.ln,
                        zip: this.state.zip,
                        dob: moment(this.state.dob).format('YYYY-MM-DD'),
                        tou: this.state.tou,
                        privacy: this.state.privacy,
                        subscribe: this.state.subscribe
                    }
                })
                    .then(resp => {
                        this.props.setUser({ ...resp.user })
                        this.props.userHasAuthenticated(true)
                        this.setState({ curr: '3', isLoading: false })
                    })
                    .catch(e => {
                        this.setState({ isLoading: false, msg: e.message, displayMsg: true })
                        console.log(e)
                    })
            } catch (e) {
                console.log(e.message)
                this.setState({ isLoading: false, err: e.message })
            }
        }
    }

    handleProfileSubmit = async event => {
        event.preventDefault()
        window.scrollTo(0, 0)
        this.setState({ isLoading: true })
        API.post('lt', 'user', {
            body: {
                image: this.state.image,
                defaultLoc: { coord: this.state.defaultLoc, defaultAddr: this.state.locIsGood ? this.state.loc : '' }
            }
        })
            .then(async resp => {
                this.props.setUser({ ...resp.user })
                await this.handleUploadImage()
                API.get('lt', 'options?prop=node')
                    .then(resp => this.setState({ options: resp.data || [] }))
                    .catch(e => console.log(e))
                this.setState({ curr: '4', isLoading: false })
            })
            .catch(e => {
                console.log(e)
                this.setState({ curr: '5', isLoading: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
            })
    }

    handlePrefSubmit = event => {
        event.preventDefault()
        window.scrollTo(0, 0)
        this.setState({ isLoading: true })
        API.post('lt', 'user', {
            body: {
                preferences: this.state.pref
            }
        })
            .then(resp => {
                this.props.setUser({ ...resp.user })
                this.setState({ curr: '5', isLoading: false })
                //const { navigate } = this.props
                //navigate('/')
            })
            .catch(e => {
                this.setState({ curr: '5', isLoading: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                console.log(e)
            })
    }

    handleDone() {
        const { navigate } = this.props
        navigate('/')
    }

    handleClearPic() {
        this.setState({ file: null, image: '', imgUrl: null })
    }

    handleBack() {
        const { navigate } = this.props
        navigate('/')
    }

    handleOpenLegal(page) {
        window.scrollTo(0, 0)
        this.setState({ legal: true, page })
    }

    handleCloseLegal() {
        this.setState({ legal: false })
    }

    handleCheckChanged(e) {
        this.setState({ [e.target.value]: e.target.checked })
    }

    handleResendCode() {
        this.setState({ codeSent: false })
        Auth.resendSignUp(this.state.email)
            .then(resp => {
                this.setState({ codeSent: true })
            })
            .catch(e => {
                console.log(e)
                this.setState({ msg: e.message, displayMsg: true })
            })
    }

    handleOptionSelect(node, cat) {
        let pref = { ...this.state.pref }
        let pidx = Object.keys(pref).findIndex(p => p === node)
        if (pidx > -1) {
            let cidx = pref[node].indexOf(cat)
            if (cidx > -1) {
                pref[node].splice(cidx, 1)
            } else pref[node].push(cat)
        } else {
            pref[node] = [cat]
        }
        this.setState({ pref })
    }

    handleMsgOK() {
        if (this.state.reset) {
            const body = {
                type: 'contactus',
                category: 'account',
                subject: `Remove Unconfirmed Account - ${this.state.email}`,
                description: `Account ${this.state.email} for ${this.state.firstName} ${this.state.lastName} needs to be deleted from the User Pool so user may re-attempt registration.`
            }
            const headers = { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` }
            API.post('lt', 'contactus', { body, headers })
                .then(resp => {
                    const { navigate } = this.props
                    navigate('/')
                })
                .catch(e => {
                    console.log(e)
                    this.setState({ msg: e.message, displayMsg: true })
                })
        } else this.setState({ msg: '', displayMsg: false })
    }

    handleLogin() {
        const { navigate } = this.props
        navigate('/login')
    }

    codeIsValid() {
        return this.state.code.length > 0
    }

    formIsValid() {
        let testpw = /[\d\W!@#$%^&*()_+-=[\]{}|<>]/
        return (
            this.state.fn.length > 0 &&
            this.state.email.length > 5 &&
            this.state.ln.length > 0 &&
            this.state.dob.length > 0 &&
            this.state.zip.length > 0 &&
            this.state.pw.length > 7 && testpw.test(this.state.pw) &&
            this.state.cpw === this.state.pw &&
            this.state.tou &&
            this.state.privacy
        )
    }

    prefIsSelected(node, cat) {
        if (this.state.pref) {
            let pidx = Object.keys(this.state.pref).findIndex(p => p === node)
            if (pidx > -1) {
                let cidx = this.state.pref[node].indexOf(cat)
                return cidx > -1
            } else return false
        } else return false
    }

    getValidation(field) {
        switch (field) {
            case 'email':
                return this.state.email.length > 2 && this.state.email.indexOf('@') > 0 && this.state.email.indexOf('.') > 1
            case 'pw':
                return this.state.pw.length > 7 && /[\d\W!@#$%^&*()_+-=[\]{}|<>]/.test(this.state.pw)
            case 'cpw':
                return this.state.cpw.length > 7 && this.state.cpw === this.state.pw
            case 'fn':
                return this.state.fn.length > 0
            case 'ln':
                return this.state.ln.length > 0
            case 'dob':
                return this.state.dob.length === 10 && moment(this.state.dob).isValid()  // /(19|20|21)\d\d\-(0[1-9]|[12][0-9]|3[01])\-(0[1-9]|1[012])/.test(this.state.dob)
            case 'zip':
                return this.state.zip.length === 5 && /\d{5}/.test(this.state.zip)
            case 'code':
                return this.state.code.length > 3 && /\d+/.test(this.state.code)
            default:
                return false
        }
    }

    pwIsStrong(part) {
        switch (part) {
            case 'length':
                return this.state.pw.length > 7
            case 'upper':
                const upper = /[A-Z]/
                return upper.test(this.state.pw)
            case 'number':
                const num = /\d/
                return num.test(this.state.pw)
            case 'special':
                const spec = /[-=!@#$%&*()_{}|<>\][+^]/
                return spec.test(this.state.pw)
            default: return false
        }
    }

    getDone() {
        return <div className="Signup">
            <div className='Signup-Heading-2'>Welcome to Gigloca, where fans can immerse themselves in a world of entertainment!</div>
            <div className='Signup-List-2'>
                We're rapidly expanding to bring excitement to every neighborhood, offering a multitude of features for your enjoyment:
                <ul>
                    <li>Discover What's Happening Now:<br />Easily stay in the loop about current events in your vicinity.</li>
                    <li>Effortlessly Plan Your Next Adventure:<br />Find the perfect spots for fun and plan your outings with ease.</li>
                    <li>Uncover Talent and Creativity:<br />Explore a diverse array of artists, musicians, and contributors who make each event special.</li>
                    <li>Share and Recommend:<br />Spread the joy by sharing and recommending events with your friends.</li>
                    <li>Provide Valuable Feedback:<br />Rate and give feedback on venues, contributors, and events to enhance the Gigloca experience.</li>
                    <li>Stay Organized:<br />Keep track of events that pique your interests, ensuring you never miss out on the excitement.</li>
                    <li>And Much More!<br />Dive into a world of possibilities and explore all that Gigloca offers.</li>
                </ul>
            </div>
            <div className='Signup-Heading-2'>Join the Fun:</div>
            <div className='Signup-List-2'>Become an integral part of the excitement by contributing your talents to the Gigloca App!
                <ul>
                    <li>Raise Awareness:<br />Promote your events and performances to a wider audience.</li>
                    <li>Expand Your Reach:<br />Attract both new and familiar faces to your events.</li>
                    <li>Effortlessly Share Your Schedule:<br />Advertise your calendar of upcoming events with ease.</li>
                    <li>Connect with Contributors:<br />Allow your venues to attract contributors for collaborative events.</li>
                    <li>Find Open Gigs:<br />Quickly locate and apply for open gigs tailored to your talents.</li>
                    <li>Who should sign up:
                        <ul>
                            <li>Venues:<br />Open your doors to a wider audience and attract contributors for your events.</li>
                            <li>Live Music Performers:<br />Showcase your talent and connect with a broad and diverse audience.</li>
                            <li>Trivia & Karaoke Hosts:<br />Bring the fun to different venues and engage with enthusiastic participants.</li>
                            <li>Security and Equipment Suppliers:<br />Connect with event organizers in need of your services.</li>
                            <li>Food Trucks and Catering:<br />Provide delicious treats to event-goers and make your mark on the local scene.</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className='Signup-Heading-2'>Join Gigloca and be a part of the thriving entertainment community!</div>
            <div className='Signup-List-2'>
                <ul>
                    <li><LinkContainer to='/my/venues'><div className='Signup-Link'>Add your Venue Profile Here</div></LinkContainer></li>
                    <li><LinkContainer to='/my/contributors'><div className='Signup-Link'>Add your Contributor Profile Here</div></LinkContainer></li>
                </ul>
            </div>
            <form onSubmit={() => this.handleDone()}>
                <LoaderButton
                    size="lg"
                    disabled={false}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Let's Get Started"
                    loadingText="Wrapping Up..."
                    className='Signup-Button'
                />
            </form>
        </div>
    }

    getOptions() {
        return this.state.options ? <div className='Signup-Options-Container'>
            <NodeTypes options={this.state.options} onOptionsSelected={this.handleOptionSelect} pref={this.state.pref} />
        </div> : null
    }

    getMessage() {
        return <div className='Signup-Confirm'>
            <div className='Signup-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Signup-Row Node-Flex-Wrap">
                <div className='Signup-Confirm-Button' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
            {this.state.showLoginLink && <div className='Signup-Row'>
                <div className='Signup-Link Info-Link' onClick={() => this.handleLogin()}>Log In</div>
            </div>}
        </div>
    }

    getdoc() {
        switch (this.state.page) {
            case 'privacy':
                return <div className='Signup-Legal'>
                    <div className='Signup-Legal-Close' onClick={() => this.handleCloseLegal()}><FaRegWindowClose /></div>
                    <Card>
                        <Card.Header><h2>GigLoca Privacy Policy</h2></Card.Header>
                        <Card.Body>
                            Effective on 10/2/2023, Gigloca values your privacy and is committed to protecting your personal information. This Privacy Policy outlines
                            how we collect, use, disclose, and safeguard your information when you visit our website, use our services, or interact with us in any way.
                            We are compliant to meet US Data Privacy Laws in 13 states: California, Virginia, Colorado, Connecticut, Utah, Iowa, Indiana, Tennessee, Texas,
                            Florida, Montana, Oregon, and Delaware.

                            <h3>Information We Collect:</h3>
                            <ol>
                                <li>Personal Information: We may collect Personal Identifiable Information (PII) such as your name, email address, phone number, birthdate,
                                    zip code and likeness when you voluntarily provide it to us.</li>
                                <li>Non-Personal Information: We may collect Non-Personal Identifiable Information such as your device information, browser type, IP address and
                                    cookies to enhance your experience and improve our services.</li>
                            </ol>
                            <h3>How We Use This Information: We may use your information for purposes including but not limited to:</h3>
                            <ol>
                                <li>Providing and improving our services</li>
                                <li>Communicating with you</li>
                                <li>Personalizing content</li>
                                <li>Conducting market research and analysis</li>
                                <li>Complying with legal obligations</li>
                            </ol>
                            <h3>Disclosing of Your Information: We may share your information with:</h3>
                            <ol>
                                <li>Third-party service providers (i.e. names; consented communication information; descriptions of venues, artists, and other service providers)</li>
                                <li>Affiliates and partners</li>
                                <li>Legal authorities when required by law</li>
                                <li>In the event of a merger, acquisition, or sets of assets</li>
                            </ol>
                            Your Choices: You can opt-out of marketing communications and exercise certain rights regarding your data.

                            Security: All data hosted by us is encrypted at rest and in flight.

                            Third-Party Links: Our website may contain links to third-party websites. We are not responsible for their privacy practices.

                            Children's Privacy: Our services are not intended for children under 18. We do not knowingly collect information on minors.

                            Changes to Privacy Policy: We may update this Privacy Policy periodically. The updated version will be posted on our website:
                            https://gigloca.com/legal?page=privacy with the effective date.

                            Contact Us: If you have questions or concerns about this Privacy Policy concerning your data, please contact us at <a href="contactgigloca@gmail.com?subject=Privacy Policy">Contact Gigloca</a>
                        </Card.Body>
                    </Card></div>
            case 'tou':
                return <div className='Signup-Legal'>
                    <div className='Signup-Legal-Close' onClick={() => this.handleCloseLegal()}><FaRegWindowClose /></div>
                    <Card>
                        <Card.Header><h2>GigLoca Terms of Use</h2></Card.Header>
                        <Card.Body>Coming Soon!</Card.Body>
                    </Card> </div>
            default:
                return null
        }
    }

    getUserForm() {
        return (
            <div className="Signup">
                {this.state.legal && this.getdoc()}
                <div className='Signup-Heading Signup-Heading-Container Signup-Group'><div className='Signup-Heading-Item Signup-Heading-Icon' onClick={() => this.handleBack()}><MdChevronLeft /></div><div className='Signup-Heading-Item'>Sign Up</div></div>
                <form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="fn" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>First Name *</Form.Label>
                        <Form.Control
                            autoFocus
                            placeholder="First Name"
                            type="text"
                            value={this.state.fn}
                            onChange={this.handleChange}
                            isValid={this.getValidation('fn')}
                            isInvalid={!this.getValidation('fn')}
                        />
                    </Form.Group>
                    <Form.Group controlId="ln" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Last Name *</Form.Label>
                        <Form.Control
                            placeholder="Last Name"
                            type="text"
                            value={this.state.ln}
                            onChange={this.handleChange}
                            isValid={this.getValidation('ln')}
                            isInvalid={!this.getValidation('ln')}
                        />
                    </Form.Group>
                    <Form.Group controlId="dob" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Date of Birth *</Form.Label>
                        <Form.Control
                            type="date"
                            value={this.state.dob}
                            onChange={this.handleChange}
                            isValid={this.getValidation('dob')}
                            isInvalid={!this.getValidation('dob')}
                        />
                    </Form.Group>
                    <Form.Group controlId="zip" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Zip Code *</Form.Label>
                        <Form.Control
                            placeholder="Zip Code (#####)"
                            type="text"
                            value={this.state.zip}
                            onChange={this.handleChange}
                            isValid={this.getValidation('zip')}
                            isInvalid={!this.getValidation('zip')}
                        />
                    </Form.Group>
                    <Form.Group controlId="email" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Email *</Form.Label>
                        <Form.Control
                            placeholder="Email (me@email.com)"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            isValid={this.getValidation('email')}
                            isInvalid={!this.getValidation('email')}
                        />
                    </Form.Group>
                    <Form.Group controlId="pw" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Password *</Form.Label>
                        <Form.Control
                            placeholder="Password"
                            value={this.state.pw}
                            onChange={this.handleChange}
                            type="password"
                            isValid={this.getValidation('pw')}
                            isInvalid={!this.getValidation('pw')}
                        />
                        <Form.Text className="Signup-Hint">Password must be at least <span className={this.pwIsStrong('length') ? 'Signup-Strong' : 'Signup-Weak'}>8 characters</span>, <span className={this.pwIsStrong('number') ? 'Signup-Strong' : 'Signup-Weak'}>1 number</span>, <span className={this.pwIsStrong('special') ? 'Signup-Strong' : 'Signup-Weak'}>1 special character</span> and <span className={this.pwIsStrong('upper') ? 'Signup-Strong' : 'Signup-Weak'}>1 uppercase letter</span></Form.Text>
                    </Form.Group>
                    <Form.Group controlId="cpw" className="form-group-lg Signup-Group">
                        <Form.Label className='Login-Input'>Re-enter Password *</Form.Label>
                        <Form.Control
                            placeholder="Re-enter Password"
                            value={this.state.cpw}
                            onChange={this.handleChange}
                            type="password"
                            isValid={this.getValidation('cpw')}
                            isInvalid={!this.getValidation('cpw')}
                        />
                    </Form.Group>
                    <div className='Signup-Checkbox'>
                        <input value="privacy" checked={this.state.privacy} type="checkbox" onChange={(event) => this.handleCheckChanged(event)} />
                        <span className='Signup-Checkbox-Text'>I accept the GigLoca <span className='Signup-Link' onClick={() => this.handleOpenLegal('privacy')}>Privacy Policy</span></span>
                    </div>
                    <div className='Signup-Checkbox'>
                        <input value="tou" type="checkbox" checked={this.state.tou} onChange={(event) => this.handleCheckChanged(event)} />
                        <span className='Signup-Checkbox-Text'>I accept the GigLoca <span className='Signup-Link' onClick={() => this.handleOpenLegal('tou')}>Terms of Use</span></span>
                    </div>
                    <div className='Signup-Checkbox'>
                        <input value="subscribe" type="checkbox" checked={this.state.subscribe} onChange={(event) => this.handleCheckChanged(event)} />
                        <span className='Signup-Checkbox-Text'>I would like to receive updates and the GigLoca Newsletter</span>
                    </div>
                    <LoaderButton
                        size="lg"
                        disabled={!this.formIsValid()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Signup"
                        loadingText="Signing up…"
                        className='Signup-Button'
                    />
                </form>
            </div>
        )
    }

    getConfirmForm() {
        return (
            <div className="Signup">
                <div className='Signup-Heading Signup-Group'>Verification Code</div>
                <div className='Signup-Heading Signup-Hint Signup-Group-2'>{`Please check ${this.state.email} for your verification code`}</div>
                <form onSubmit={this.handleConfirmSubmit}>
                    <Form.Group controlId="code" className="form-group-lg Signup-Group-3">
                        <Form.Label className='Login-Input'>Code</Form.Label>
                        <Form.Control
                            autoFocus
                            type="tel"
                            placeholder="Code from Email"
                            value={this.state.code}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <div className='Signup-Heading Signup-Hint Signup-Group-2'>{`${this.state.codeSent ? `Code resent to ${this.state.email}` : "Didn't receive your code?"}`} <span className='Signup-Link' onClick={() => this.handleResendCode()}>Resend Code</span>.</div>
                    <LoaderButton
                        size="lg"
                        disabled={!this.codeIsValid()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Verify"
                        loadingText="Verifying…"
                        className='Signup-Button'
                    />
                </form>
            </div>
        )
    }

    getProfileForm() {
        return <div className="Signup">
            <div className='Signup-Heading Signup-Group Signup-Welcome'>{`Welcome ${this.state.fn}!`}</div>
            <div className='Signup-Photo-Container'>
                {
                    this.state.imgUrl ? <img src={this.state.imgUrl} alt="Profile" className='Signup-Profile' onClick={() => this.handleClearPic()} /> :
                        <label className="Signup-Profile Signup-Profile-Icon">
                            <input type="file" onChange={event => this.handleImageChange(event)} ref={ref => this.fileInput = ref} />
                            {this.state.imageSize ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}
                        </label>
                }
            </div>
            <div className='Signup-Profile-Form'>
                <label className='Signup-Profile-Text'>
                    <input type="file" onChange={event => this.handleImageChange(event)} ref={ref => this.fileInput = ref} />
                    {`${this.state.imgUrl ? 'Want to Change it?' : 'Edit Profile Picture'}`}
                </label>
                <div className='Signup-Default-Container'>
                    <div className='Signup-Heading Signup-Group Signup-Default-Text'>Set Your Default Location</div>
                </div>
                <div className='Signup-Group-3'>
                    <div className='Signup-Heading-Container'><div className='Signup-Heading-Item'>
                        <Form.Group controlId="loc" className="form-group-lg">
                            <Form.Label className='Login-Input Signup-Label-Left'>Location</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Home Base"
                                value={this.state.loc}
                                onChange={this.handleChange}
                            />
                        </Form.Group></div>
                        {this.state.locIsGood && <div className='Signup-Heading-Item'><img src={Checkmark} alt="Verified" className='Signup-Heading-Checkmark' /></div>}
                    </div>
                </div>
                <div className='Signup-Hint Signup-Link' onClick={() => this.handleLocationCheck()}>Look Up</div>
                <form onSubmit={this.handleProfileSubmit}>
                    <LoaderButton
                        size="lg"
                        disabled={false}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text={this.state.image || this.state.locIsGood ? "Next" : "Skip"}
                        loadingText="Updating…"
                        className='Signup-Button'
                    /></form>
            </div>
        </div>
    }

    getPrefForm() {
        return (
            <div className="Signup">
                <div className='Signup-Heading Signup-Group'>Select Favorites:</div>
                <div>{this.getOptions()}</div>
                <div className='Signup-Profile-Form'>
                    <form onSubmit={this.handlePrefSubmit}>
                        <LoaderButton
                            size="lg"
                            disabled={false}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="Let's Gigit!"
                            loadingText="Updating…"
                            className='Signup-Button'
                        /></form>
                </div>
            </div>
        )
    }

    render() {
        console.log(this.state, this.props)
        return (
            <div className='Signup-Container'>
                {this.state.curr === '5' ? this.getDone() : (this.state.curr === '4' ? this.getPrefForm() : (this.state.curr === '3' ? this.getProfileForm() : (this.state.curr === '2' ? this.getConfirmForm() : this.getUserForm())))}
                {
                    this.state.displayMsg && this.getMessage()
                }
            </div>
        )
    }
}

export default withRouter(Signup)