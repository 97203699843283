import { Component } from 'react'
import { BsHouseDoor } from 'react-icons/bs'
import { MdOutlineVerified } from 'react-icons/md'
import { GoVerified } from 'react-icons/go'
import { IoMdLogIn, IoMdLogOut } from 'react-icons/io'
import { BiReset } from 'react-icons/bi'
import './bottomMenu.css'

class BottomMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            active: false
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ active: true }), 800)
    }

    handleLeftSelect() {
        this.props.onLeft()
    }

    handleRightSelect() {
        this.props.onRight()
    }

    handleGiglit() {
        this.props.onGiglit()
    }

    getLeft() {
        if (this.props.menu && this.props.menu.left && this.props.menu.left === 'house') {
            return <div className='BottomMenu-Item' onClick={() => this.handleLeftSelect()}>
                <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><BsHouseDoor /></div>
                <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>Home</div>
            </div>
        } else if (this.props.menu && this.props.menu.left && this.props.menu.left === 'login') {
            return <div className='BottomMenu-Item' onClick={() => this.handleLeftSelect()}>
                <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><IoMdLogIn /></div>
                <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>Login</div>
            </div>
        } else if (this.props.menu && this.props.menu.left && this.props.menu.left === 'logout') {
            return <div className='BottomMenu-Item' onClick={() => this.handleLeftSelect()}>
                <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><IoMdLogOut /></div>
                <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>Logout</div>
            </div>
        } else return <div className='BottomMenu-Item'></div>
    }

    getGiglit() {
        if (this.props.menu && this.props.menu.giglit && this.props.menu.status) {
            switch (this.props.menu.status) {
                case 'inactive':
                    return <div className='BottomMenu-Item' onClick={() => this.handleGiglit()}>
                        <div className={`BottomMenu-Giglit BottomMenu-Giglit-Red`}><div style={{ paddingTop: '3px' }} className='BottomMenu-Giglit-Icon'><MdOutlineVerified /></div></div>
                    </div>
                case 'active':
                    return <div className='BottomMenu-Item' onClick={() => this.handleGiglit()}>
                        <div className='BottomMenu-Giglit BottomMenu-Giglit-Green'><div style={{ paddingTop: '3px' }} className='BottomMenu-Giglit-Icon'><GoVerified /></div></div>
                    </div>
                default:
                    return <div className='BottomMenu-Item'></div>
            }
        } else return <div className='BottomMenu-Item'></div>
    }

    getRight() {
        if (this.props.menu && this.props.menu.right) {
            return <div className='BottomMenu-Item' onClick={() => this.handleRightSelect()}>
                <div className='BottomMenu-Item-Row BottomMenu-Giglit-Icon'><BiReset /></div>
                <div className='BottomMenu-Item-Row BottomMenu-Item-Text'>Reset</div>
            </div>
        } else return <div className='BottomMenu-Item'></div>
    }

    render() {
        //console.log(this.props)
        return <div className='BottomMenu-Container'>
            {this.getLeft()}
            {this.getGiglit()}
            {this.getRight()}
        </div>
    }
}

export default BottomMenu