const config = {
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://e5393no4n6.execute-api.us-east-1.amazonaws.com/prod/"
    },
    s3: {
        REGION: "us-east-1",
        BUCKET: "localtalent-upload"
    },
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_a1AjE7Foi',
        APP_CLIENT_ID: '11381ec3qt8sbf5gr5qkjqt79o',
        IDENTITY_POOL_ID: 'us-east-1:6774c6c8-3928-4be7-817e-4e0401615b47'
    }
}

export default config
