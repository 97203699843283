import { Component } from 'react'
import { Auth, API } from 'aws-amplify'
import withRouter from './components/withRouter'
import Routes from './routes'
import './App.css'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAuthenticated: false,
      isLoadingBackground: true,
      isGiglocaScreen: false,
      user: null,
      loadingCss: 'App-Loading-Bar-Init',
      loadedCss: 'App-Faded',
      back: [],
      myLoc: null,
      data: null,
      options: null,
      mode: 'fan',
      filter: null
    }

    this.handleLogout = this.handleLogout.bind(this)
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.setState({ isAuthenticated: true })
        API.get('lt', 'user')
          .then(resp => {
            this.setState({
              loadingCss: 'App-Loading-Bar-Final',
              user: resp.data || null
            })
          })
      })
      .catch(e => {
        console.log(e)
         const { navigate } = this.props
        navigate('/')
      })
    setTimeout(() => this.setState({ loadingCss: 'App-Loading-Bar-Post', isGiglocaScreen: true }), 230)
    setTimeout(() => this.setState({ isLoadingBackground: false, loadedCss: 'App-Showing' }), 500)
    setTimeout(() => this.setState({ isGiglocaScreen: false }), 2500)
  }

  userHasAuthenticated = authenticated => this.setState({ isAuthenticated: authenticated, isGiglocaScreen: false })
  setUser = user => this.setState({ user })
  onBack = (refresh) => this.handleBack(refresh)
  addHistory = path => this.handleAddHistory(path)
  onMyLoc = myLoc => this.setState({ myLoc })
  setData = data => this.setState({ data })
  setOptions = options => this.setState({ options })
  setMode = mode => this.setState({ mode })
  setFilter = filter => this.setState({ filter })
  async handleLogout() {
    if (this.state.isAuthenticated) {
      try {
        await Auth.signOut({ global: true })
        this.setState({ user: null, data: null })
        this.userHasAuthenticated(false)
        const { navigate } = this.props
        navigate('/')
      } catch (e) {
        console.log(e.message)
      }
    }
  }

  handleGiglocaScreen() {
    this.setState({ isGiglocaScreen: false })
  }

  handleAddHistory(path) {
    let back = [...this.state.back]
    back.push(path)
    this.setState({ back })
  }

  handleBack(refresh) {
    //stack 'back' values LIFO
    const { navigate } = this.props
    let back = this.state.back ? [...this.state.back] : []
    let goto = back.length > 0 ? back.splice(back.length - 1, 1) : ['/']
    this.setState({ back })
    navigate(`${goto[0]}${refresh ? '?refresh=true' : ''}`)
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setUser: this.setUser,
      user: this.state.user,
      onBack: this.onBack,
      onAddHistory: this.addHistory,
      onLogout: this.handleLogout,
      onMyLoc: this.onMyLoc,
      myLoc: this.state.myLoc,
      data: this.state.data,
      setData: this.setData,
      options: this.state.options,
      setOptions: this.setOptions,
      setMode: this.setMode,
      mode: this.state.mode,
      setFilter: this.setFilter,
      filter: this.state.filter
    }
    //console.log(this.state)
    return (
      !this.state.isLoadingBackground ?
        <div>
          {
            this.state.isGiglocaScreen ? <div className='Gigloca-Container'>
              <div className='Gigloca-Title' onClick={() => this.handleGiglocaScreen()}>GigLoca</div>
              <div className='Gigloca-Text-1'>All Data Will Be Deleted Prior to Production.</div>
              <div className='Gigloca-Text-2'>Please Explore Our App and Never Stop Playing!</div>
            </div> :
              <Routes childProps={childProps} />
          }
        </div> :
        <div className="App-Loading">
          <div className={`App-Loading-Bar ${this.state.loadingCss}`}></div>
        </div>
    )
  }
}

export default withRouter(App);
