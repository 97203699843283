import { Component } from 'react'
import moment from 'moment'
import Carousel from 'react-bootstrap/Carousel'
import { FaStar, FaRegGrinStars, FaHeart, FaStore } from 'react-icons/fa'
import { FaHeartCircleCheck } from 'react-icons/fa6'
import { BsClockHistory, BsCalendar2Heart, BsCalendar3 } from 'react-icons/bs'
import { BiBookmarkHeart } from 'react-icons/bi'
import { RiPinDistanceFill } from 'react-icons/ri'
import { GiPartyPopper } from 'react-icons/gi'
import './miniWide.css'

class MiniWide extends Component {

    getMyStars() {
        let stars = []
        for (let i = 0; i < parseInt(this.props.data.irated); i++) {
            stars.push(i + 1)
        }
        return stars.map(s => {
            return <div key={`star-${s}`} className='MiniWide-Icon' style={{ color: 'yellow' }}><FaStar /></div>
        })
    }

    render() {
        return <div className={`MiniWide-Container MiniWide-Container-${this.props.type || 'default'}`}>
            <div className='MiniWide-Section MiniWide-Left'>
                {
                    this.props.data.imageArray && this.props.data.imageArray.length > 0 ? <Carousel controls={false} touch={false} indicators={false} fade>
                        {
                            this.props.data.imageArray.map((img, imgidx) => {
                                return <Carousel.Item key={`ci-${imgidx}`}>
                                    <img src={`https://localtalent-upload.s3.amazonaws.com/public/${img}`} className='MiniWide-CI' alt={''} />
                                </Carousel.Item>
                            })
                        }
                    </Carousel> :
                        <div className='MiniWide-CI MiniWide-CI-Alt'>{this.props.type === 'event' ? <GiPartyPopper /> : <FaStore />}</div>
                }
            </div>
            <div className='MiniWide-Section'>
                {
                    this.props.type && this.props.type === 'event' ? <div className='MiniWide-Middle'>
                        <div className='MiniWide-Title MiniWide-Mid-Top'>{this.props.data.title}</div>
                        <div className='MiniWide-Text MiniWide-Mid-Mid'>{`${this.props.data.venueName || ''} (${this.props.data.category})`}</div>
                        <div className='MiniWide-Text MiniWide-Mid-Bottom MiniWide-Flex'>
                            <div className='MiniWide-Item' style={{ fontSize: '10pt', marginTop: '10px' }}><BsCalendar3 /></div>
                            <div className='MiniWide-Item' style={{ fontSize: '10pt', marginTop: '10px' }}>{moment(this.props.data.startTime).format('l')}</div>
                            <div className='MiniWide-Item' style={{ fontSize: '10pt', marginTop: '10px' }}><BsClockHistory /></div>
                            <div className='MiniWide-Item' style={{ fontSize: '10pt', marginTop: '10px' }}>{moment(this.props.data.startTime).format('h:mm A')}</div>
                            {
                                this.props.data.distance ? <div className={`MiniWide-Flex${this.props.near ? ' MiniWide-Nearby' : ''}`}>
                                    <div className='MiniWide-Item'><RiPinDistanceFill /></div>
                                    <div className='MiniWide-Item' style={{ fontSize: '10pt' }}>{this.props.data.distance.toFixed(1)} mi</div>
                                </div> : null
                            }
                        </div>
                    </div> :
                        <div className='MiniWide-Middle'>
                            <div className='MiniWide-Title MiniWide-Mid-Top'>{this.props.data.name}</div>
                            <div className='MiniWide-Mid-Mid MiniWide-Text'>{`${this.props.data.category}`}</div>
                            <div className='MiniWide-Mid-Bottom MiniWide-Flex-2'>
                                {
                                    this.props.data.distance ? <div className={`MiniWide-Flex${this.props.near ? ' MiniWide-Nearby' : ''}`}>
                                        <div className='MiniWide-Item'><RiPinDistanceFill /></div>
                                        <div className='MiniWide-Item'>{this.props.data.distance.toFixed(1)} mi</div>
                                    </div> : null
                                }
                                {
                                    this.props.data.irated && <div style={{marginTop: '-3px'}} className='MiniWide-Flex'><div className='MiniWide-Icon' style={{ color: '#fff' }}><FaRegGrinStars /></div>{this.getMyStars()}</div>
                                }
                            </div>
                        </div>
                }
            </div>
            <div className='MiniWide-Section MiniWide-Right'>
                <div className='MiniWide-Row'>
                    {
                        this.props.data.stars && this.props.data.stars > 0 ? <div style={{ marginLeft: '10px' }} className='MiniWide-Flex'>
                            <div className='MiniWide-Stars'><FaStar /></div>
                            <div className='MiniWide-Stars-Text'>{this.props.data.stars.toFixed(1)}</div>
                        </div> : null
                    }
                </div>
                <div className='MiniWide-Row'>
                    {
                        (this.props.data.interested || this.props.data.attending) && (((this.props.data.interested || 0) + (this.props.data.attending || 0)) > 0) ? <div style={{ marginLeft: '10px' }} className='MiniWide-Flex'>
                            <div className='MiniWide-Heart'><FaHeart /></div>
                            <div className='MiniWide-Heart-Text'>{((this.props.data.interested || 0) + (this.props.data.attending || 0)) > 1000 ? `${(((this.props.data.interested || 0) + (this.props.data.attending || 0)) / 1000).toFixed(1)}k` : (this.props.data.interested || 0) + (this.props.data.attending || 0)}</div>
                        </div> : null
                    }
                    {
                        this.props.data.followers && this.props.data.followers > 0 ? <div style={{ marginLeft: '10px' }} className='MiniWide-Flex'>
                            <div className='MiniWide-Heart'><FaHeart /></div>
                            <div className='MiniWide-Heart-Text'>{this.props.data.followers > 1000 ? `${(this.props.data.followers / 1000).toFixed(1)}k` : this.props.data.followers}</div>
                        </div> : null
                    }
                </div>
                <div className='MiniWide-Row'>
                    {this.props.data.iamfollowing && <div style={{ marginLeft: '12px', marginTop: '-5px', color: '#f52c74' }} className='MiniWide-Icon'><FaHeartCircleCheck /></div>}
                    {this.props.data.iamattending && <div style={{ marginLeft: '12px', marginTop: '-5px', color: '#fff' }} className='MiniWide-Icon'><BsCalendar2Heart /></div>}
                    {this.props.data.iaminterested && <div style={{ marginLeft: '12px', marginTop: '-5px', color: '#fff' }} className='MiniWide-Icon'><BiBookmarkHeart /></div>}
                </div>
            </div>
        </div>
    }
}

export default MiniWide
