import React from 'react'
import { LinkContainer } from "react-router-bootstrap"
import Logo from '../images/GigLoca_Logo_Light.png'

const style = {width: '100%', height: '100vh', paddingTop: '20vh', textAlign: 'center', color: '#fff', background: 'radial-gradient(circle at 300%, #0f142b 10%, #f42c74 10%, #0f142b 100%)'}

const NotFound = () => 
    <div style={style}>
        <img src={Logo} style={{width: '100%', padding: '5px'}} alt="Gigloca" />
        <h3>Sorry, page <span style={{ fontWeight: 'bold' }}>not</span> found!</h3>
        <LinkContainer style={{textDecoration: 'underline', cursor: 'pointer', fontSize: '12pt'}} to='/'><span>Try here!</span></LinkContainer>
    </div>
export default NotFound