import { Component } from 'react'
import moment from 'moment'
import Carousel from 'react-bootstrap/Carousel'
import { BsCalendarFill, BsClockHistory } from 'react-icons/bs'
import { BiBookmarkHeart } from 'react-icons/bi'
import { FaStar, FaHeart, FaClipboardCheck } from 'react-icons/fa'
import { RiCloseCircleLine, RiPinDistanceFill } from 'react-icons/ri'
import './smallCard.css'

class SmallCard extends Component {
    handleSelect() {
        //console.log('card select', this.props.data.id)
        if (this.props.onSelect) this.props.onSelect(this.props.data.id, this.props.type)
    }

    handleDismiss() {
        //console.log(this.props.data.id, this.props.slot || null)
        if (this.props.onDismiss) this.props.onDismiss(this.props.data.id, this.props.slot || null)
    }

    handleAccept() {
        if(this.props.onAccept) this.props.onAccept(this.props.data.id, this.props.slot || null)
    }

    getSelectedStarsArray() {
        let stars = this.props.data.irated || 0
        let selected = []
        for (let x = 0; x < stars; x++) {
            selected.push(x + 1)
        }        
        return selected
    }

    getUnselectedStarsArray() {
        let stars = this.props.data.irated || 1
        if(stars !== 1) stars++
        let unselected = []
        for (let x = stars; x < 6; x++) {
            unselected.push(x)
        }        
        
        return unselected
    }

    render() {     
        //console.log(this.props)   
        return <div className={`SmallCard-Container`}>
            <div className={`SmallCard-Color SmallCard-${this.props.inactive ? 'Disabled' : (this.props.type || 'default')}`}>
                <div className='SmallCard-Header'>
                    <div className='SmallCard-Row'>
                        <div className='SmallCard-Heart'>{this.props.data.iaminterested ? <BiBookmarkHeart /> : ((this.props.data.iamattending || this.props.data.iamfollowing) ? <FaHeart /> : null)}</div>
                        {
                            this.props.onDismiss && <div className='SmallCard-Dismiss' onClick={() => this.handleDismiss()}><RiCloseCircleLine /></div>
                        }
                        {
                            this.props.onAccept && <div className='SmallCard-Accept' onClick={() => this.handleAccept()}><FaClipboardCheck /></div>
                        }
                        {
                                this.props.data.stars && this.props.data.stars > 0 ? <div style={{ marginLeft: '10px' }} className='SmallCard-Flex'>
                                    <div className='SmallCard-Stars'><FaStar /></div>
                                    <div className='SmallCard-Stars-Text'>{this.props.data.stars.toFixed(1)}</div>
                                </div> : null
                            }
                    </div>
                    <div className='SmallCard-Row'>
                        {
                            this.props.type === 'event' ? <div style={{cursor: 'pointer'}} className='SmallCard-Text' onClick={() => this.handleSelect()}>{this.props.data.title ? this.props.data.title : ''}</div> :
                                <div style={{cursor: 'pointer'}} className='SmallCard-Text' onClick={() => this.handleSelect()}>{this.props.data.name ? this.props.data.name : this.props.data.category}</div>
                        }
                    </div>
                </div>
                {
                    this.props.type === 'event' ? <div style={{cursor: 'pointer'}} onClick={() => this.handleSelect()} className='SmallCard-Footer'>
                        <div className='SmallCard-Row'>
                            <div className='SmallCard-Icon'><BsCalendarFill /></div>
                            <div className='SmallCard-Text'>{this.props.data.startTime ? moment(this.props.data.startTime).format('ll') : ''}</div>
                        </div>
                        <div className='SmallCard-Row'>
                            <div className='SmallCard-Icon'><BsClockHistory /></div>
                            <div className='SmallCard-Text'>{this.props.data.startTime ? moment(this.props.data.startTime).format('h:mm A') : ''}</div>
                        </div>
                    </div> : <div style={{cursor: 'pointer'}} onClick={() => this.handleSelect()} className='SmallCard-Footer'>
                        <div className='SmallCard-Row'></div>
                        <div className='SmallCard-Row'>
                            {
                                this.props.data.distance ? <div className='SmallCard-Flex'>
                                    <div className='SmallCard-Icon'><RiPinDistanceFill /></div>
                                    <div className='SmallCard-Text'>{this.props.data.distance.toFixed(1)} mi</div>
                                </div> : null
                            }                            
                        </div>
                    </div>
                }
                <div className='SmallCard-BG'>
                    {
                        this.props.data.imageArray && this.props.data.imageArray.length > 0 ? <Carousel style={{ width: '100%' }} controls={false} touch={false} indicators={false} fade>
                            {
                                this.props.data.imageArray.map((img, imgidx) => {
                                    return <Carousel.Item key={`ci-${imgidx}`} style={{ width: '100%' }}>
                                        <div style={{ margin: '0 auto', width: 'fit-content' }}><img src={`https://localtalent-upload.s3.amazonaws.com/public/${img}`} className='SmallCard-CI' alt='' /></div>
                                    </Carousel.Item>
                                })
                            }
                        </Carousel> : null
                    }
                </div>
                {this.props.inactive && <div className='SmallCard-Inactive'>Inactive</div>}
                {this.props.rate && <div className='SmallCard-Rating'>
                    {
                        this.getSelectedStarsArray().map(star => {
                            return <div key={`star-${star}`} className='SmallCard-Star-Selected'><FaStar /></div>
                        })
                    }
                    {
                        this.getUnselectedStarsArray().map(star => {
                            return <div key={`star-${star}`} className='SmallCard-Star-Unselected'><FaStar /></div>
                        })
                    }
                </div>}
            </div>
        </div>
    }
}

export default SmallCard