import { Component } from 'react'
import { BsDiscFill } from 'react-icons/bs'
import { MdLiquor, MdTableRestaurant, MdTheaterComedy, MdOutlineDeliveryDining, MdOutlineTakeoutDining, MdDoNotTouch, MdOutlinePets, MdOutlineSportsBar, MdOutlineNightlife, MdOutlineTheaterComedy, MdOutlineQuestionAnswer, MdOutlineFace5 } from 'react-icons/md'
import { IoIosBeer, IoMdRestaurant } from 'react-icons/io'
import { IoTransgenderOutline, IoPeopleOutline } from 'react-icons/io5'
import { BiSolidDrink, BiSolidParking, BiExpandVertical, BiReset } from 'react-icons/bi'
import { GiWineBottle, GiParkBench, GiCampfire, GiOldMicrophone, GiDrumKit, GiPoolTableCorner, GiGolfFlag, GiBowlingStrike, GiDart, GiBarStool, GiSing, GiCastle } from 'react-icons/gi'
import { FaRestroom, FaDrum, FaPersonBooth } from 'react-icons/fa'
import { FaPeopleRoof, FaPeopleGroup } from 'react-icons/fa6'
import { LiaCocktailSolid, LiaPaintBrushSolid } from 'react-icons/lia'
import { AiOutlineWifi } from 'react-icons/ai'
import { TbLetterV, TbShirtSport } from 'react-icons/tb'
import { GrWheelchair } from 'react-icons/gr'
import { PiChurchLight, PiGuitarDuotone } from 'react-icons/pi'
import { SiGameandwatch } from 'react-icons/si'
import { FcMultipleInputs } from 'react-icons/fc'
import '../containers/signup.css'

class NodeTypes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            expanded: []
        }
    }

    handleSelectPref(node, cat) {
        this.props.onOptionsSelected(node, cat)
    }

    handleExpand(section) {
        let expanded = [...(this.state.expanded || [])]
        let idx = expanded.indexOf(section)
        if (idx > -1) expanded.splice(idx, 1)
        else expanded.push(section)
        this.setState({ expanded })
    }

    handleResetOptions() {
        this.props.onResetOptions()
    }

    prefIsSelected(node, cat) {
        if (this.props.pref) {
            let pidx = Object.keys(this.props.pref).findIndex(p => p === node)
            if (pidx > -1) {
                let cidx = this.props.pref[node].indexOf(cat)
                return cidx > -1
            } else return false
        } else return false
    }

    getIcon(i) {
        switch (i) {
            case "All":
                return <FcMultipleInputs />
            case "Liquor":
                return <MdLiquor />
            case "Beer":
                return <IoIosBeer />
            case "Cocktails":
                return <LiaCocktailSolid />
            case "Wine":
                return <GiWineBottle />
            case "Happy Hour":
                return <BiSolidDrink />
            case "Indoor Seating":
                return <MdTableRestaurant />
            case "Outdoor Seating":
                return <GiParkBench />
            case "Covered Patio":
                return <FaPeopleRoof />
            case "Outdoor Firepit":
                return <GiCampfire />
            case "Restrooms":
                return <FaRestroom />
            case "Karaoke":
                return <GiOldMicrophone />
            case "Band":
                return <GiDrumKit />
            case "Comedy":
                return <MdTheaterComedy />
            case "DJ":
                return <BsDiscFill />
            case "Free Wi-Fi":
                return <AiOutlineWifi />
            case "Pool Tables":
                return <GiPoolTableCorner />
            case "Golden Tee":
                return <GiGolfFlag />
            case "Silver Strike Bowling":
                return <GiBowlingStrike />
            case "Darts":
                return <GiDart />
            case "Sports":
                return <TbShirtSport />
            case "Delivery":
                return <MdOutlineDeliveryDining />
            case "No-Contact Delivery":
                return <MdDoNotTouch />
            case "Takeout":
                return <MdOutlineTakeoutDining />
            case "Parking":
                return <BiSolidParking />
            case "Valet":
                return <TbLetterV />
            case "Wheelchair Accessible":
                return <GrWheelchair />
            case "LGBTIQA+ Friendly":
                return <IoTransgenderOutline />
            case "Pet Friendly":
                return <MdOutlinePets />
            case "Bar":
                return <MdOutlineSportsBar />
            case "Nightclub":
                return <MdOutlineNightlife />
            case "Comedy Club":
                return <MdOutlineTheaterComedy />
            case "Restaurant":
                return <IoMdRestaurant />
            case "Cornhole":
                return <SiGameandwatch />
            case "Church":
                return <PiChurchLight />
            case "Outdoor Bar":
                return <GiBarStool />
            case "Acoustics Band":
                return <PiGuitarDuotone />
            case "Solo Artist":
                return <GiSing />
            case "Tribute Band":
                return <FaDrum />
            case "Group":
                return <FaPeopleGroup />
            case "Duet":
                return <IoPeopleOutline />
            case "Comedian":
                return <MdOutlineTheaterComedy />
            case "Trivia":
                return <MdOutlineQuestionAnswer />
            case "Performer":
                return <MdOutlineFace5 />
            case "Booth":
                return <FaPersonBooth />
            case "Paint":
                return <LiaPaintBrushSolid />
            case "Bounce House":
                return <GiCastle />
            default: return null
        }
    }

    getOptions() {
        return this.props.options ? <div style={{ width: '97%', margin: '0 auto', paddingBottom: '60px', height: '270px', overflowY: 'auto' }}>
            {
                this.props.onResetOptions && <div className='Signup-Options-Reset' onClick={() => this.handleResetOptions()}><BiReset /><div style={{ marginLeft: '5px' }}>Clear Selected Options</div></div>
            }
            {
                this.props.options.map((node, oidx) => {
                    return <div key={`option-${oidx}`} style={{ width: '100%' }}>
                        <div className={`Signup-Nodes-Section Signup-${node.ptype.replace(/\s/g, '')}`} onClick={() => this.handleExpand(node.ptype)}>{node.ptype}<span style={{ marginLeft: '5px' }}><BiExpandVertical /></span></div>
                        <div className='Signup-Node-Container'>
                            {this.state.expanded && this.state.expanded.indexOf(node.ptype) > -1 && <div className={`Signup-Node-Item Signup-${node.ptype.replace(/\s/g, '')}${this.prefIsSelected(node.ptype, 'All') ? ' Signup-Node-Item-Selected' : ''}`}><div className='Signup-Node-Item-Text' onClick={() => this.handleSelectPref(node.ptype, 'All')}>All<br /><div style={{ fontSize: '14pt' }}>{this.getIcon('All')}</div></div></div>}
                            {
                                this.state.expanded && this.state.expanded.indexOf(node.ptype) > -1 && node.categories.map((cat, cidx) => {
                                    return <div key={`node-cat-${cidx}`} className={`Signup-Node-Item Signup-${node.ptype.replace(/\s/g, '')}${this.prefIsSelected(node.ptype, Object.keys(cat)[0]) ? ' Signup-Node-Item-Selected' : ''}`}>
                                        <div className='Signup-Node-Item-Text' onClick={() => this.handleSelectPref(node.ptype, Object.keys(cat)[0])}>{Object.keys(cat)[0]}<br /><div style={{ fontSize: '14pt' }}>{this.getIcon(Object.keys(cat)[0])}</div></div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                })
            }
        </div> :
            null
    }

    render() {
        return <div>{this.getOptions()}</div>
    }
}

export default NodeTypes