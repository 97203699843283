import React from "react"
import { Route, Routes } from "react-router-dom"
import NotFound from './containers/notFound'
import Login from './containers/login'
import Signup from './containers/signup'
import Node from "./containers/node"
import Admin from './containers/admin'
import Notifications from './containers/notifications'
import Pref from './containers/pref'
export default function Links({ childProps }) {
  return (
    <Routes>
      <Route path="/" element={<Admin {...childProps} />} />
      <Route path="/login" element={<Login {...childProps} />} />
      <Route path="/signup" element={<Signup {...childProps} />} />
      <Route path="/notifications" element={<Notifications {...childProps} />} />
      <Route path="/pref" element={<Pref {...childProps} />} />
      <Route path="/venue/:id" element={<Node  {...childProps} />} />
      <Route path="/contributor/:id" element={<Node  {...childProps} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}