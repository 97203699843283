import { Component } from 'react'
import { API, Storage } from 'aws-amplify'
import Form from "react-bootstrap/Form"
import SmallCard from '../components/smallCard'
import NodeTypes from '../components/nodeTypes'
import withRouter from '../components/withRouter'
import LoaderButton from '../components/loaderButton'
import moment from 'moment'
import { v1 as uuidv1 } from 'uuid'
import { FaArrowLeft, FaSignOutAlt, FaLocationArrow, FaMapMarkedAlt, FaPlusSquare, FaUserAltSlash } from 'react-icons/fa'
import { MdOutlineEdit, MdAddAPhoto, MdOutlineSubscriptions, MdOutlineMailLock, MdMarkEmailRead, MdImageNotSupported } from 'react-icons/md'
import { LiaBirthdayCakeSolid } from 'react-icons/lia'
import { BsPinMap } from 'react-icons/bs'
import { RiPinDistanceFill } from 'react-icons/ri'
import { FcInvite } from 'react-icons/fc'
import Checkmark from '../images/checkmark.svg'
import Logo from '../images/GigLoca_Logo_Light.png'
import { getAddress } from '../helpers'
import './node.css'

class Pref extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            fn: '',
            ln: '',
            zip: '',
            dob: '',
            subscribe: false,
            defaultLoc: null,
            loc: '',
            lastLoc: '',
            locIsGood: false,
            dist: 25,
            file: null,
            imgUrl: null,
            image: null,
            edit: false,
            editImg: false,
            editPref: false,
            bounce: false,
            isLoading: false,
            node: null,
            event: null,
            options: null,
            pref: null,
            expanded: [],
            greeting: 'Welcome ',
            refresh: false,
            invite: '',
            userFailed: false,
            userPassed: false,
            lastInvite: '',
            imageSize: false,
            msg: '',
            displayMsg: false
        }

        this.handleOptionSelect = this.handleOptionSelect.bind(this)
        this.handleEventDismiss = this.handleEventDismiss.bind(this)
        this.handleEventSelect = this.handleEventSelect.bind(this)
        this.handleContributorSelect = this.handleContributorSelect.bind(this)
        this.handleVenueSelect = this.handleVenueSelect.bind(this)
        this.handleNodeDismiss = this.handleNodeDismiss.bind(this)
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this.setState({ bounce: true })
            setTimeout(() => {
                this.handleNavigate('login')
            }, 2500)
        } else {
            API.get('lt', 'user')
                .then(resp => {
                    this.props.setUser(resp.data)
                    this.handleReset(resp.data)
                    this.setState({ greeting: moment().hours() > 17 ? 'Good Evening, ' : (moment().hours() > 12 ? 'Good Afternoon, ' : (moment().hours() > 5 ? 'Good Morning, ' : 'Welcome, ')) })
                })
                .catch(err => {
                    console.log(err)
                    this.handleReset()
                    this.setState({ greeting: moment().hours() > 17 ? 'Good Evening, ' : (moment().hours() > 12 ? 'Good Afternoon, ' : (moment().hours() > 5 ? 'Good Morning, ' : 'Welcome, ')) })
                })
        }
    }

    handleNavigate(page) {
        this.props.onAddHistory(`pref`)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        this.props.onBack(this.state.refresh)
    }

    handleReset(u) {
        if (u || this.props.user) {
            const user = u || this.props.user
            const pref = user.preferences ? JSON.parse(JSON.stringify(user.preferences)) : null
            this.setState({
                fn: user.firstName || '',
                ln: user.lastName || '',
                dob: user.dob || '',
                zip: user.zip || '',
                defaultLoc: user.defaultLoc || null,
                loc: user.defaultAddr || '',
                image: user.image || '',
                subscribe: user.subscribe || false,
                pref: { ...pref },
                edit: false,
                editImg: false,
                editPref: false,
                file: null,
                imgUrl: null,
                dist: user.dist || 10
            })
            window.scrollTo(0, 0)
        } else this.handleNavigate('/')
    }

    handleLogout() {
        this.props.onLogout()
    }

    handleLocChanged(event) {
        this.setState({ loc: event.target.value.replace(/[^a-zA-Z0-9- ]/g, "") })
    }

    handleDistChanged(event) {
        let dist = event.target.value === 0 ? 1 : (event.target.value)
        this.setState({ dist })
    }

    handleGetMyLoc() {
        const gl = navigator.geolocation
        gl.getCurrentPosition(position => {
            API.get('lt', `geo?coord=${position.coords.latitude},${position.coords.longitude}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    } else {
                        this.setState({ locIsGood: false, loc: 'Not Found' })
                    }
                })
        }, err => {
            console.log(err)
        })
    }

    handleLocationCheck() {
        if (this.state.loc && this.state.loc !== this.state.lastLoc) {
            API.get('lt', `geo?address=${this.state.loc.replace(/#/g, '%23')}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    }
                })
                .catch(e => {
                    this.setState({ loc: '', locIsGood: false })
                })
        }
    }

    handleImageChange(e) {
        const file = e.target.files[0]
        if (file.size < 5242880) {
            if (file.type.indexOf('image/') > -1) {
                let image = uuidv1() + file.type.replace('image/', '.')
                this.setState({ file, image, imgUrl: URL.createObjectURL(file), editImg: true, edit: false, editPref: false, imageSize: false })
            }
        } else this.setState({ imageSize: true })
        this.fileInput.value = ''
    }

    async handleUploadImage() {
        if (this.state.file && this.state.image.length > 0) {
            await Storage.put(`${this.state.image}`, this.state.file, {
                progressCallback(progress) { console.log(`Uploaded: ${progress.loaded}/${progress.total}`) }
            })
            this.props.setUser({ ...this.props.user, image: this.state.image })
            this.setState({ editImg: false })
            API.post('lt', 'user', { body: { image: this.state.image } })
                .then(resp => {
                    this.props.setUser({ ...this.props.user, image: this.state.image })
                    this.setState({ editImg: false })
                })
                .catch(e => {
                    this.setState({ editImg: false })
                    this.handleReset()
                    console.log(e)
                })
        } else {
            API.post('lt', 'user', { body: { image: '' } })
                .then(resp => {
                    this.props.setUser({ ...this.props.user, image: this.state.image })
                    this.setState({ editImg: false })
                })
                .catch(e => {
                    this.setState({ editImg: false })
                    this.handleReset()
                    console.log(e)
                })
        }
    }

    handleClearPic() {
        this.setState({ file: null, image: '', imgUrl: null, editImg: true, edit: false, editPref: false, imageSize: false })
    }

    handleEdit(section) {
        if (section === '1') {
            this.setState({ edit: !this.state.edit, fn: this.props.user.firstName || '', ln: this.props.user.lastName || '', dob: this.props.user.dob || '', zip: this.props.user.zip || '', defaultLoc: this.props.user.defaultLoc || null, loc: this.props.user.defaultAddr || '', image: this.props.user.image || '', editImg: false, editPref: false, file: null, imgUrl: null, dist: this.props.user.dist || this.state.dist })
        } else if (section === '2') {
            if (!this.state.options) {
                API.get('lt', 'options?prop=node')
                    .then(resp => this.setState({ options: resp.data || [] }))
                    .catch(e => console.log(e))
            }
            this.setState({ editPref: !this.state.editPref, edit: false, fn: this.props.user.firstName || '', ln: this.props.user.lastName || '', dob: this.props.user.dob || '', zip: this.props.user.zip || '', defaultLoc: this.props.user.defaultLoc || null, loc: this.props.user.defaultAddr || '', image: this.props.user.image || '', editImg: false, file: null, imgUrl: null, dist: this.props.user.dist || this.state.dist })
        }
    }

    handleOptionSelect(node, cat) {
        let pref = Object.assign({}, this.state.pref)
        let idx = pref[node] ? pref[node].indexOf(cat) : -1
        if (idx > -1) {
            pref[node].splice(idx, 1)
        } else {
            if (pref[node]) {
                pref[node].push(cat)
            } else pref[node] = [cat]
        }
        this.setState({ pref })
     }

    handleSubmit = async event => {
        event.preventDefault()
        if (this.formIsValid()) {
            window.scrollTo(0, 0)
            this.setState({ isLoading: true })
            const body = {
                firstName: this.state.fn,
                lastName: this.state.ln,
                zip: this.state.zip,
                dob: moment(this.state.dob).format('YYYY-MM-DD'),
                defaultLoc: this.state.defaultLoc,
                defaultAddr: this.state.loc,
                subscribe: this.state.subscribe,
                dist: this.state.dist
            }
            API.post('lt', 'user', { body })
                .then(resp => {
                    this.props.setUser({ ...this.props.user, ...body })
                    this.setState({ isLoading: false, edit: false })
                })
                .catch(e => {
                    this.setState({ isLoading: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                    console.log(e)
                })
        }
    }

    handleCheckChanged(e) {
        this.setState({ [e.target.value]: e.target.checked })
    }

    handleSavePref() {
        window.scrollTo(0, 0)
        API.post('lt', 'user', {
            body: {
                preferences: this.state.pref
            }
        })
            .then(resp => {
                this.props.setUser({ ...this.props.user, preferences: this.state.pref })
            })
            .catch(e => {
                this.setState({msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true})
                console.log(e)
            })
        this.setState({ editPref: false })
    }

    handleChange = event => {
        switch (event.target.id) {
            case 'zip':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d]/g, "") })
                break
            case 'loc':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-z,A-Z0-9 ]/g, "") })
                break
            case 'dob':
                this.setState({ [event.target.id]: event.target.value.replace(/[^\d-/\\]/g, '') })
                break
            case 'invite':
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9-@. ]/g, ""), userFailed: false, userPassed: false })
                break
            default:
                this.setState({ [event.target.id]: event.target.value.replace(/[^a-zA-Z0-9- ]/g, "") })
        }
    }

    handleEventSelect(eid) {
        this.handleNavigate(`event/${eid}`)
    }

    handleVenueSelect(vid) {
        this.handleNavigate(`venue/${vid}`)
    }

    handleContributorSelect(cid) {
        this.handleNavigate(`contributor/${cid}`)
    }

    handleEventDismiss(e) {
        let status = this.props.user.interested.findIndex(i => i.id === e) > -1 ? 'interested' : 'attending'
        API.put('lt', `interest/${e}`, { body: { status } })
            .then(resp => {
                let user = { ...this.props.user }
                let idx = user[status].findIndex(i => i.id === e)
                if (idx > -1) {
                    user[status].splice(idx, 1)
                    this.props.setUser(user)
                    this.setState({ refresh: true })
                }
            })
            .catch(e => console.log(e))
    }

    handleNodeDismiss(n) {
        API.put('lt', `interest/${n}`, { body: { status: 'following' } })
            .then(resp => {
                let user = { ...this.props.user }
                let idx = user.following.findIndex(i => i.id === n)
                if (idx > -1) {
                    user.following.splice(idx, 1)
                    this.props.setUser(user)
                    this.setState({ refresh: true })
                }
            })
            .catch(e => console.log(e))
    }

    handleInvite() {
        if (this.state.invite.toLowerCase() !== this.state.lastInvite.toLowerCase()) {
            API.post('lt', `invite`, { body: { email: this.state.invite, type: 'invite' } })
                .then(resp => {
                    this.setState({ userFailed: false, userPassed: true, invite: '', lastInvite: this.state.invite })
                })
                .catch(e => {
                    this.setState({ msg: "Unable to invite user.", userFailed: true, userPassed: false, displayMsg: true })
                    console.log(e)
                })
        } else this.setState({ msg: "Duplicate user request.", userFailed: true, userPassed: false, displayMsg: true })
    }
    
    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false })
    }

    getBounce() {
        return <div style={{ marginTop: '250px' }} className='Pref-Content'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Pref-Bounce'>For your All-Access Pass to all that Gigloca has to offer, create your account or sign in to Gigloca.</div>
        </div>
    }

    getMyPref() {
        let prefs = []
        if (this.props.user && this.props.user.preferences) {
            Object.keys(this.props.user.preferences).forEach(pref => {
                this.props.user.preferences[pref].forEach(cat => {
                    prefs.push(cat)
                })
            })
        }
        return prefs
    }

    getValidation(field) {
        switch (field) {
            case 'fn':
                return this.state.fn.length > 0
            case 'ln':
                return this.state.ln.length > 0
            case 'dob':
                return this.state.dob.length === 10 && moment(this.state.dob).isValid()  // /(19|20|21)\d\d\-(0[1-9]|[12][0-9]|3[01])\-(0[1-9]|1[012])/.test(this.state.dob)
            case 'zip':
                return this.state.zip.length === 5 && /\d{5}/.test(this.state.zip)
            case 'invite':
                return this.state.invite.length > 2 && this.state.invite.indexOf('@') > 0 && this.state.invite.indexOf('.') > 1
            default:
                return false
        }
    }

    getMessage() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    formIsValid() {
        return (
            this.state.fn.length > 0 &&
            this.state.ln.length > 0 &&
            this.state.dob.length > 0 &&
            this.state.zip.length > 0
        )
    }

    render() {
        //console.log(this.state, this.pref)
        return <div className='Pref-Container Node-Venue'>
            {this.state.bounce ? this.getBounce() : (this.props.user ? <div>
                <div className='Pref-Back Node-Flex-Spread'>
                    <div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div>
                    <div className='Node-Arrow' onClick={() => this.handleLogout()}><div style={{ marginTop: '-13px' }}><FaSignOutAlt /></div></div>
                </div>
                <div className='Node-CI'>{this.props.user && this.props.user.image && <img className='Node-CI Pref-Img-BG' src={`https://localtalent-upload.s3.amazonaws.com/public/${this.props.user.image}`} alt='' />}</div>
                <div className='Pref-Content'>
                    <div className='Node-Row Node-Flex-Spread'>
                        <div className='Node-Flex'>
                            {
                                this.state.imgUrl || this.state.image ? <div><img src={this.state.imgUrl || `https://localtalent-upload.s3.amazonaws.com/public/${this.state.image}`} className='Pref-Profile-Img' onClick={() => this.handleClearPic()} alt='' /></div> :
                                    <div className="Pref-Profile-Icon">{this.state.imageSize ? <span><MdImageNotSupported />Size</span> : <MdAddAPhoto />}</div>
                            }
                            <label className='Pref-Edit-Icon'>
                                <input type="file" onChange={event => this.handleImageChange(event)} ref={ref => this.fileInput = ref} />
                                <div><MdOutlineEdit /></div>
                            </label>
                        </div>
                        <div onClick={() => this.handleEdit('1')} className='Pref-Name Node-Flex'>
                            {`${this.state.greeting}${this.props.user && this.props.user.firstName ? this.props.user.firstName : ''}`}
                            <div style={{ marginTop: '3px' }}><MdOutlineEdit /></div>
                        </div>
                    </div>
                    {
                        this.state.edit && <div className='Node-Row Pref-Form'>
                            <form onSubmit={this.handleSubmit}>
                                <Form.Group controlId="fn" className="form-group-lg Signup-Group">
                                    <Form.Label className='Pref-Label'>First Name *</Form.Label>
                                    <Form.Control
                                        autoFocus
                                        placeholder="First Name"
                                        type="text"
                                        value={this.state.fn}
                                        onChange={this.handleChange}
                                        isValid={this.getValidation('fn')}
                                    />
                                </Form.Group>
                                <Form.Group controlId="ln" className="form-group-lg Signup-Group">
                                    <Form.Label className='Pref-Label'>Last Name *</Form.Label>
                                    <Form.Control
                                        placeholder="Last Name"
                                        type="text"
                                        value={this.state.ln}
                                        onChange={this.handleChange}
                                        isValid={this.getValidation('ln')}
                                    />
                                </Form.Group>
                                <Form.Group controlId="dob" className="form-group-lg Signup-Group">
                                    <Form.Label className='Pref-Label'>Date of Birth *</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={this.state.dob}
                                        onChange={this.handleChange}
                                        isValid={this.getValidation('dob')}
                                    />
                                </Form.Group>
                                <Form.Group controlId="zip" className="form-group-lg Signup-Group">
                                    <Form.Label className='Pref-Label'>Zip Code *</Form.Label>
                                    <Form.Control
                                        placeholder="Zip Code (#####)"
                                        type="text"
                                        value={this.state.zip}
                                        onChange={this.handleChange}
                                        isValid={this.getValidation('zip')}
                                    />
                                </Form.Group>
                                <div className='Signup-Checkbox'>
                                    <input value="subscribe" type="checkbox" checked={this.state.subscribe} onChange={event => this.handleCheckChanged(event)} />
                                    <span className='Signup-Checkbox-Text'>I would like to receive updates and the GigLoca Newsletter</span>
                                </div>
                                <Form.Group controlId="loc" className="form-group-lg">
                                    <Form.Label className='Pref-Label'>Location<span style={{ marginLeft: '8px' }} onClick={() => this.handleGetMyLoc()}><FaLocationArrow /></span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Location"
                                        value={this.state.loc}
                                        onChange={event => this.handleLocChanged(event)}
                                    />
                                </Form.Group>
                                <div className='Node-Row Node-Flex-Spread'>
                                    <div className='Pref-Label Pref-Link' onClick={() => this.handleLocationCheck(this.state.loc)}>Look Up{this.state.locIsGood && <span style={{ marginLeft: '5px' }}><img src={Checkmark} alt="Verified" className='Search-Location-Checkmark' /></span>}</div>
                                    <div style={{ width: '50%' }}>
                                        <Form.Label style={{ color: '#fff' }}>Distance {this.state.dist} mi</Form.Label>
                                        <Form.Range value={this.state.dist} onChange={event => this.handleDistChanged(event)} />
                                    </div>
                                </div>
                                <div className='Node-Row'>
                                    <LoaderButton
                                        size="lg"
                                        disabled={!this.formIsValid()}
                                        type="submit"
                                        isLoading={this.state.isLoading}
                                        text="Update"
                                        loadingText="Updating…"
                                        className='Signup-Button'
                                    />
                                    <div className='Node-Row Pref-Cancel-Button' onClick={() => this.handleReset()}>Cancel</div>
                                </div>
                            </form>
                        </div>
                    }
                    {
                        this.state.editImg && <div className="Node-Row Node-Flex-Spread">
                            <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleUploadImage()}>Save</div>
                            <div className='Pref-Button Pref-Button-Cancel' onClick={() => this.handleReset()}>Cancel</div>
                        </div>
                    }
                    <div className='Node-Row Node-Flex-Wrap'>
                        {this.props.user && this.props.user.dob && <div className='Node-Flex '><div className='Node-Addr-Icon'><LiaBirthdayCakeSolid /></div><div className='Pref-Text'>{moment(this.props.user.dob).format('l')}</div></div>}
                        {this.props.user && this.props.user.address && <div className='Node-Flex '><div className='Node-Addr-Icon'><FaMapMarkedAlt /></div><div className='Pref-Text'>{getAddress(this.props.user.address)}</div></div>}
                        {this.props.user && this.props.user.zip && <div className='Node-Flex '><div className='Node-Addr-Icon'><BsPinMap /></div><div className='Pref-Text'>{this.props.user.zip}</div></div>}
                        {this.props.user && this.props.user.subscribe && <div className='Node-Flex '><div className='Node-Addr-Icon'><MdOutlineSubscriptions /></div><div className='Pref-Text'>Subscribed</div></div>}
                        {this.props.user && this.props.user.email && <div className='Node-Flex '><div className='Node-Addr-Icon'>{this.props.user.emailConsent ? <MdMarkEmailRead /> : <MdOutlineMailLock />}</div><div className='Pref-Text'>{this.props.user.email}</div></div>}
                        {this.props.user && this.props.user.dist && <div className='Node-Flex '><div className='Node-Addr-Icon'><RiPinDistanceFill /></div><div className='Pref-Text'>{this.props.user.dist} mi</div></div>}
                    </div>
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>My Preferences</div>
                        <div className='Node-Divider'></div>
                        <div className='Node-Addr-Icon Pref-Clickable' onClick={() => this.handleEdit('2')}><MdOutlineEdit /></div>
                    </div>
                    {!this.state.editPref && this.props.user && this.props.user.preferences && <div className='Node-Row Node-Flex-Left'>
                        {
                            this.getMyPref().map((mp, mpidx) => {
                                return <div key={`mp-${mpidx}`} className='Node-Item'>{mp}</div>
                            })
                        }
                    </div>}
                    {this.state.editPref && <div className='Node-Row Pref-Form'>
                        <NodeTypes options={this.state.options} onOptionsSelected={this.handleOptionSelect} pref={this.state.pref} />
                        <div className="Node-Row Node-Flex-Spread">
                            <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleSavePref()}>Save</div>
                            <div className='Pref-Button Pref-Button-Cancel' onClick={() => this.handleReset()}>Cancel</div>
                        </div>
                    </div>}
                    <Form.Group controlId="invite" style={{ marginLeft: '5px', marginRight: '5px' }} className="form-group form-group-lg">
                        <Form.Label className='Pref-Label'>Invite a Friend</Form.Label>
                        <div className='Node-Flex'>
                            <Form.Control
                                placeholder="User Email"
                                type="text"
                                value={this.state.invite}
                                onChange={this.handleChange}
                                isValid={this.getValidation('invite')}
                            />
                            <div className='My-Link-Icon' onClick={() => this.handleInvite()}><FaPlusSquare /></div>
                            {this.state.userFailed ? <div className='My-Link-Icon' style={{ color: '#ff0000' }}><FaUserAltSlash /></div> : (this.state.userPassed ? <div className='My-Link-Icon'><FcInvite /></div> : null)}
                        </div>
                    </Form.Group>
                    {
                        ((this.props.user.attending && this.props.user.attending.length > 0) || (this.props.user.interested && this.props.user.interested.length > 0)) && [...(this.props.user.attending || []), ...(this.props.user.interested || [])].filter(ai => moment(ai.startTime).isBefore(moment()) && moment(ai.stopTime).isAfter(moment())).length > 0 && <div><div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text'>Happening Now</div>
                            <div className='Node-Divider'></div>
                        </div>
                            <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    [...(this.props.user.attending || []), ...(this.props.user.interested || [])].filter(ai => moment(ai.startTime).isBefore(moment()) && moment(ai.stopTime).isAfter(moment())).map((ea, eaidx) => {
                                        return <SmallCard key={`ean-${eaidx}`} data={{ ...ea, stars: null }} type='event' onSelect={this.handleEventSelect} onDismiss={this.handleEventDismiss} rate />
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        ((this.props.user.attending && this.props.user.attending.length > 0) || (this.props.user.interested && this.props.user.interested.length > 0)) && [...(this.props.user.attending || []), ...(this.props.user.interested || [])].filter(ai => moment(ai.startTime).isAfter(moment())).length > 0 && <div><div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text'>Upcoming Events</div>
                            <div className='Node-Divider'></div>
                        </div>
                            <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    [...(this.props.user.attending || []), ...(this.props.user.interested || [])].filter(ai => moment(ai.startTime).isAfter(moment())).map((ea, eaidx) => {
                                        return <SmallCard key={`eaf-${eaidx}`} data={{ ...ea, stars: null }} type='event' onSelect={this.handleEventSelect} onDismiss={this.handleEventDismiss} />
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        this.props.user.following && this.props.user.following.length > 0 && <div><div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text'>Following</div>
                            <div className='Node-Divider'></div>
                        </div>
                            <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    this.props.user.following.map((n, nidx) => {
                                        return <SmallCard key={`follow-${nidx}`} data={{ ...n, stars: null }} type={`${n.nodetype === 'Venue' ? 'venue' : 'contributor'}`} onSelect={n.nodetype === 'Venue' ? this.handleVenueSelect : this.handleContributorSelect} onDismiss={this.handleNodeDismiss} rate />
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        this.props.user.attending && this.props.user.attending.length > 0 && this.props.user.attending.filter(ai => moment(ai.stopTime).isBefore(moment())).length > 0 && <div><div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text'>Previously Attended Events</div>
                            <div className='Node-Divider'></div>
                        </div>
                            <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    this.props.user.attending.filter(ai => moment(ai.stopTime).isBefore(moment())).map((ea, eaidx) => {
                                        return <SmallCard key={`eao-${eaidx}`} data={{ ...ea, stars: null }} type='event' onSelect={this.handleEventSelect} onDismiss={this.handleEventDismiss} rate />
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        this.props.user.followinginactive && this.props.user.followinginactive.length > 0 && <div><div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text'>Following Inactive</div>
                            <div className='Node-Divider'></div>
                        </div>
                            <div className='Node-Row Node-Flex-Wrap'>
                                {
                                    this.props.user.followinginactive.map((n, nidx) => {
                                        return <SmallCard key={`followi-${nidx}`} data={{ ...n, stars: null }} type={`${n.nodetype === 'Venue' ? 'venue' : 'contributor'}`} onDismiss={this.handleNodeDismiss} inactive />
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div> : null)}
            {
                this.state.displayMsg && this.getMessage()
            }
        </div>
    }
}

export default withRouter(Pref)